import {React} from 'react'
import Tx from './Tx';

export default function TxsTable(props){
    const {txs,batchID,setTxs,setPeople} = props;
    return (
        <>
            <h2>Transactions</h2>
            <table className="modern-table">
            <thead>
            <tr>
                <th style={{width:"55%"}}>transaction</th>
                <th style={{width:"15%",textAlign : "center"}}>value</th>
                <th style={{width:"30%",textAlign : "center"}}>modify</th>
            </tr>
            </thead>
                <tbody>
                    {txs?.map((tx,index) => {
                        return <Tx name={tx.data.name} value={parseFloat(tx.value).toFixed(2)} key={index} txID={tx.id} batchID={batchID} txs={txs} setTxs={setTxs} setPeople={setPeople}/>
                    })}
                </tbody>
            </table>
            <br/>
        </>
    )
}