import {React,useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = (props) => {
    const {setBatchName} = props;
    const navigate = useNavigate();
    const [name,setName] = useState("");
    useEffect(()=>{
        setBatchName(name);
    },[setBatchName,name])
    const HandleChange = (event) => {
        setName(event.target.value);
    }
    const HandleSubmit = (event) => {
        event.preventDefault();
        if (name.match("^.{1,50}$")!=null){
            fetch("/api/new",{
                headers : {
                'Content-Type' : 'application/json'
                },
                body:JSON.stringify({
                    name:name
                }),
                method:"post"
            }).then((res)=>res.json()).then((resp)=>{
                if (resp.success) {
                    navigate(`/${resp.id}`);
                }
            });
        }
    }
	return (
    <section>
    <form onSubmit={HandleSubmit}>
        <label>
            <input onChange={HandleChange} type="text" pattern="^.{1,50}$" title="new batch's name, e.g., bonnie doon trip" placeholder="new batch name" value={name}/>
        </label>
        <button type="submit">create</button>
    </form>
    </section>
	);
};

export default LandingPage;
