import {React} from 'react';
import {useNavigate} from 'react-router-dom';

export default function NewTx(props){
    const {batchID} = props;
    const navigate = useNavigate();
    const HandleSubmit = (event) => {
        event.preventDefault();
        fetch(`/api/${batchID}/tx`).then((res)=>res.json()).then((resp)=>{
            if (resp.success){
                navigate(`/${batchID}/${resp.tx.id}`);
            } else {
                console.log(resp);
            }
        });
    };
    return (
        <form method="GET" onSubmit={HandleSubmit}>
            <button type="submit">new transaction</button>
        </form>
    )
}