import React from "react"

export default function DownloadCSV (props) {
    const {batchID,batchName} = props;
    const HandleSubmit = (event) => {
        event.preventDefault();
        fetch(`/api/${batchID}/dlcsv`).then((resp) => {
            if (resp.success || resp.status===200) {
                resp.blob().then((blob) => {
                    // Creating new object of PDF file
                    const fileURL =
                        window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = `${batchName}.csv`;
                    alink.click();
                });
            } else {
                console.log(resp);
            }
        });
        
    }
    return (
    <form method="GET" onSubmit={HandleSubmit}>
        <button className="action_btn" type="submit">download csv</button>
    </form>
    )
}