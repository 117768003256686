import React from 'react';
import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import LandingPage from './pages/landing_page';
import TxEditor from './pages/tx_editor';
import BatchPage from './pages/batch_page';

function App() {
	const [batchName,setBatchName] = useState("");
	return (
		<main>
		<header>
		<h1>splitit</h1>
		<h3>{batchName}</h3>
		</header>
			<Router>
				<Routes>
					<Route exact path='/' element={<LandingPage setBatchName={setBatchName} />} />
					<Route path='/:batchID' element={<BatchPage batchName={batchName} setBatchName={setBatchName}/>}  />
					<Route path='/:batchID/:txID' element={<TxEditor setBatchName={setBatchName}/>}   />
				</Routes>
			</Router>
		</main>
	);
}

export default App;
