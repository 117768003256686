import {React,useState} from 'react'
import TxDetail from './TxDetail';

export default function TxDetails(props){
    const {txData,setTxData,people,batchID,txID} = props;
    const [warningMsg,setWarningMsg] = useState("");
    return (
        <>
        <table className="modern-table">
            <thead>
                <tr>
                    <th>who</th>
                    <th>in</th>
                    <th>out</th>
                </tr>
            </thead>
            <tbody>
                    {people?.map((person,index) => {
                        return <TxDetail who={person.name} setWarningMsg={setWarningMsg} txData={txData} key={index} batchID={batchID} txID={txID} setTxData={setTxData}/>
                    })}
            </tbody>
        </table>
        <p>{warningMsg}</p>
        </>
    )
}