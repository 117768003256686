import { React, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import PeopleTable from "../components/PeopleTable";
import TxsTable from "../components/TxsTable";
import NewPersonForm from "../components/NewPersonForm";
import NewTx from "../components/NewTx";
import DownloadCSV from "../components/DownloadCSV";

const BatchPage = (props) => {
    const { batchID } = useParams();
    const { batchName, setBatchName } = props;
    const [people,setPeople] = useState([]);
    const [txs,setTxs] = useState([]);
    useEffect(()=>{
            fetch(`/api/${batchID}`).then((res)=>res.json()).then((resp)=>{
                if (resp.success){
                    setPeople(resp.batch.people);
                    setTxs(resp.txs);
                    setBatchName(resp.batch.name);
                } else {
                    console.log(resp)
                }
            });
    },[batchID,setBatchName]);

	return (
        <div>
        <section>
        <PeopleTable people={people} txs={txs}/>
        <br/>
        <NewPersonForm batchID={batchID} setPeople={setPeople}/>
        </section>
        <section>
        <TxsTable txs={txs} batchID={batchID} setTxs={setTxs} setPeople={setPeople}/>
        <NewTx batchID={batchID}/>
        </section>
        <section>
        <DownloadCSV batchID={batchID} batchName={batchName}/>
        </section>
        </div>
	);
};

export default BatchPage;
