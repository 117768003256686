import { React, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import NewPersonForm from "../components/NewPersonForm";
import BackToBatchButton from "../components/BackToBatchButton";
import TxName from "../components/TxName";
import TxDetails from "../components/TxDetails";

const TxPage = (props) => {
    const {batchID,txID} = useParams();
    const { setBatchName } = props;
    const [people,setPeople] = useState([]);
	const [txData,setTxData] = useState({
		"name" : "",
		"in" : {},
		"out" : {}
	});
    useEffect(()=>{
		fetch(`/api/${batchID}`).then((res)=>res.json()).then((resp)=>{
			if (resp.success){
				setPeople(resp.batch.people);
                setBatchName(resp.batch.name);
			} else {
				console.log(resp)
			}
		});
    },[batchID,setBatchName]);
    useEffect(()=>{
		fetch(`/api/${batchID}/${txID}`).then((res)=>res.json()).then((resp)=>{
			if (resp.success){
				setTxData(resp.tx.data);
			} else {
				console.log(resp)
			}
		});
    },[batchID,txID,setBatchName]);

	return (
	<div>
		<br/>
		<BackToBatchButton batchID={batchID}/>
		<section>
			<form>
				<TxName txData={txData} setTxData={setTxData} batchID={batchID} txID={txID}/>
				<br/><br/>
				<TxDetails setTxData={setTxData} txData={txData} people={people} txID={txID} batchID={batchID}/>
			</form>
			<br/>
			<NewPersonForm batchID={batchID} setPeople={setPeople}/>
		</section>
		<BackToBatchButton batchID={batchID}/>
	</div>
	);
};

export default TxPage;
