import {React} from 'react'

export default function BackToBatchButton(props){
    const {batchID} = props;

    return (
		<form className="done" method="GET" action={`/${batchID}`}>
			<button type="submit">back</button>
		</form>
    )
}