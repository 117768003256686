import {React, useEffect, useState} from 'react';

export default function TxName(props){
    const {txData,setTxData,batchID,txID} = props;
    const [name,setName] = useState(txData.name);
    useEffect(()=>{
        setName(txData.name);
    },[txData]);
    const HandleChange = (event) => {
        console.log(event.target.value)
        if (event.target.value.match("^.{0,300}[A-Za-z0-9 .!?\"-]$") != null || event.target.value === "") {
            setName(event.target.value);
            fetch(`/api/${batchID}/${txID}`,{
                headers : {
                'Content-Type' : 'application/json'
                },
                body:JSON.stringify({
                    name:event.target.value
                }),
                method:"post"
            }).then((res)=>res.json()).then((resp)=>{
                if (resp.success){
                    setTxData(resp.tx.data);
                } else {
                    console.log(resp);
                }
            });
        } else {
            alert("alphanumeric only")
        }
    }
    return (
        <label>
            <p>reference</p>
            <input className="biginput" type="text" onChange={HandleChange} value={name} placeholder="short description"/>
        </label>
    )
}
