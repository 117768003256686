import {React} from 'react'
import Person from './Person'

export default function PeopleTable(props){
    const {people,txs} = props;
    return (
        <>
            <h2>People</h2>
            <table className="modern-table">
                <thead>
                    <tr>
                        <th>person</th>
                        <th>debt</th>
                    </tr>
                </thead>
                <tbody>
                    {people?.map((person,index) => {
                        return <Person name={person.name} debt={person.debt} key={index} txs={txs}/>
                    })}
                </tbody>
            </table>
        </>
    )
}