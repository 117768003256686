import { React, useState } from "react";

export default function NewPersonForm(props) {
    const {batchID,setPeople} = props;
    const [name,setName] = useState("");
    const HandleSubmit = (event) => {
        event.preventDefault();
        if (name.match("^.{0,50}[A-Za-z0-9]$") != null){
            fetch(`/api/${batchID}/person`,{
                headers : {
                'Content-Type' : 'application/json'
                },
                body:JSON.stringify({
                    name:name
                }),
                method:"post"
            }).then((res) => res.json()).then((resp)=>{
                if (resp.success){
                    setPeople(resp.batch.people);
                    setName("");
                } else {
                    console.log(resp)
                }
            });
        } else {
            alert("names must be alphanumeric only");
        }
    }
    const HandleChange = (event) => {
        setName(event.target.value);
    }
    return (
        <form onSubmit={HandleSubmit}>
            <label>
                <input type="text" value={name} onChange={HandleChange} title="new person's name" placeholder="new person"/>
            </label>
            <button type="submit">add</button>
        </form>
    )
}