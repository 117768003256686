import {React,useEffect,useState} from 'react'

export default function TxDetail(props){
    const {who,txData,batchID,txID,setTxData,setWarningMsg} = props;
    const [i,setI] = useState(txData["in"][who] || "");
    const [o,setO] = useState(txData["out"][who] || "");
    useEffect(()=>{
        setI(txData["in"][who] || "");
    },[txData,who]);
    useEffect(()=>{
        setO(txData["out"][who] || "");
    },[txData,who]);
    const HandleChangeIn = (event) => {
        setI(event.target.value);
        fetch(`/api/${batchID}/${txID}`,{
            headers : {
            'Content-Type' : 'application/json'
            },
            body:JSON.stringify({
                "in" : {
                    ...txData["in"],
                    [who]:event.target.value
                }
            }),
            method:"post"
        }).then((res)=>res.json()).then((resp)=>{
            if (resp.success){
                setTxData(resp.tx.data);
                setWarningMsg(resp.msg || "")
            } else {
                console.log(resp);
            }
        });
    }
    const HandleChangeOut = (event) => {
        setO(event.target.value);
        fetch(`/api/${batchID}/${txID}`,{
            headers : {
            'Content-Type' : 'application/json'
            },
            body:JSON.stringify({
                "out" : {
                    ...txData["out"],
                    [who]:event.target.value
                }
            }),
            method:"post"
        }).then((res)=>res.json()).then((resp)=>{
            if (resp.success){
                setTxData(resp.tx.data);
                setWarningMsg(resp.msg || "")
            } else {
                console.log(resp);
            }
        });
    }
    return (
        <tr>
            <td>{who}</td>
            <td><input onChange={HandleChangeIn} className="numberInput" type="number" min="0" step="0.01" value={i}/></td>
            <td><input onChange={HandleChangeOut} className="numberInput" type="number" min="0" step="0.01" value={o}/></td>
        </tr>
    )
}