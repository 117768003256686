import {React} from 'react'

export default function Person(props){
    const {name, debt} = props;
    return (
        <tr>
            <td>{name}</td>
            <td>{parseFloat(debt).toFixed(2)}</td>
        </tr>
    )
}