import {React} from 'react'
import { useNavigate } from 'react-router-dom';

export default function Tx(props){
    const {name, value,batchID,txID,setTxs,setPeople} = props;
    const navigate = useNavigate();
    const HandleEdit = (event) => {
        event.preventDefault();
        navigate(`/${batchID}/${txID}`);
    }
    const HandleDelete = (event) => {
        event.preventDefault();
        fetch(`/api/${batchID}/${txID}/delete`).then((res)=>res.json()).then((resp)=>{
            if (resp.success){
                fetch(`/api/${batchID}`).then((res)=>res.json()).then((resp)=>{
                    if (resp.success){
                        setTxs(resp.txs);
                        setPeople(resp.batch.people);
                    } else {
                        console.log("delete succeeded, then failed to read batch+txs", resp);
                    }
                });
            } else {
                console.log("delete failed",resp);
            }
        });
    }
    return (
        <tr>
            <td>{name}</td>
            <td>{parseFloat(value).toFixed(2)}</td>
        <td style={{textAlign : "center"}}>
        <form className="action_btn" method="GET" onSubmit={HandleEdit}>
          <button className="action_btn" type="submit">edit</button>
        </form>
        <> </>
        <form className="action_btn delete_btn" method="GET" onSubmit={HandleDelete}>
          <button className="action_btn" type="submit">delete</button>
        </form>
      </td>
        </tr>
    )
}